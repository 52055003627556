import React from 'react';
import FAQ from '../PageComponent/FAQComponents/faq';

export default function FAQSection(){
  return (
    <div>
        <FAQ />
    </div>
  );
};

