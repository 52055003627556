import React from 'react';
import Contact from '../PageComponent/ContactComponents/contact';

export default function ContactSection(){
  return (
    <div>
        <Contact />
    </div>
  );
};

