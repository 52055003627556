import React from 'react';
import Gallery from '../PageComponent/GalleryComponent/gallery';

export default function GallerySection() {
  return (
    <div>
        <Gallery />
    </div>
  );
};
